import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

// import TabReducer from '../common/tab/tabReducer'
// import AuthReducer from '../auth/authReducer'
import AuthReducer from './modules/auth/authReducer'
// import MemberReducer from './modules/members/Reducer'
// import GroupsReducer from './modules/groups/Reducer'
import MainReducer from './routes/Reducer'

const rootReducer = combineReducers({
    form: formReducer,
    toastr: toastrReducer,
    // members: MemberReducer,
    // groups: GroupsReducer,
    auth: AuthReducer,
    main: MainReducer
})

export default rootReducer
