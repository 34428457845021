// export default {
//     API_URL: 'https://brasal-veiculos-extrato-api.herokuapp.com',
//     OAPI_URL: 'https://brasal-veiculos-extrato-api.herokuapp.com',
//     UserKey: '_brasal_veiculos_extrato'
// }
export default {
  API_URL: "https://api-extratoveiculos.brasal.com.br",
  OAPI_URL: "https://api-extratoveiculos.brasal.com.br",
  UserKey: "_brasal_veiculos_extrato",
};
