import React from "react";
import axios from 'axios'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Icon from "@material-ui/core/Icon";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";

// import InfoOutline from "@material-ui/icons/InfoOutline";
// import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import InfoOutlined from "@material-ui/icons/InfoOutlined"

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
// import Danger from "components/Typography/Danger.jsx";

import consts from '../../consts'

// import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// const style = {
//   customCardContentClass: {
//     paddingLeft: "0",
//     paddingRight: "0"
//   },
//   cardIconTitle: {
//     ...cardTitle,
//     marginTop: "15px",
//     marginBottom: "0px"
//   }
// };


class RegularTables extends React.Component {
// class RegularTables extends Component{

  INITIAL_STATE = {
    me: JSON.parse(localStorage.getItem(consts.UserKey)),
    total: 0,
    comissao: 0,
    last_update: '',
    items: []
  }

  constructor(props){
    super(props)
    this.state = this.INITIAL_STATE;
    this.get()
  }

  get(){
    let items = []
    axios.get(`${consts.API_URL}/?vendedor=${this.state.me.vendedor}`)
    .then(resp => {
      let total = 0;
      let comissao = 0;
      let last_update = resp.data.dt_geracao
      resp.data.data.map(data => {
        // console.log(data)
        total += data.valor
        comissao += data.comissao
        const item = [
          data.codServico,
          `R$ ${data.valor.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`,
          `R$ ${data.comissao.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`,
          data.dt_encerr,
          data.nr_os
        ]
        return items.push(item)
      })
      // console.log(comissao)
      this.setState({items, total, comissao, last_update })
    })
  }

  render(){
    const { classes } = this.props;
    // console.log(itens)
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <LocalOffer />
              </CardIcon>
              <p className={classes.cardCategory}>Total</p>
              <h3 className={classes.cardTitle}>
                <small>
                  {`R$ ${this.state.total.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}
                </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <a href="#this" onClick={() => this.get()}>
                  <Update />
                  Verificar atualização
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Comissão</p>
              <h3 className={classes.cardTitle}>
                <small>
                  {`R$ ${this.state.comissao.toLocaleString('pt-BR', {minimumFractionDigits: 2})}`}
                </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <a href="#this" onClick={() => this.get()}>
                  <Update />
                  Verificar atualização
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <InfoOutlined></InfoOutlined>
              </CardIcon>
              <p className={classes.cardCategory}>Vendas</p>
              <h3 className={classes.cardTitle}>
                {this.state.items.length}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <a href="#this" onClick={() => this.get()}>
                  <Update />
                  Verificar atualização
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <DateRange />
              </CardIcon>
              <p className={classes.cardCategory}>Atualizado em</p>
              <h3 className={classes.cardTitle}>
                <small>
                  {this.state.last_update}
                </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <a href="#this" onClick={() => this.get()}>
                  <Update />
                  Verificar atualização
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Vendas</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["Código", "Valor", "Comissão", "Data", "Número"]}
                tableData={this.state.items}
                coloredColls={[1, 2]}
                colorsColls={["primary","primary"]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  )};
}

// RegularTables.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default withStyles(dashboardStyle)(RegularTables);
// export default withStyles([style,dashboardStyle])(RegularTables);
