// import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import consts from '../../consts'
var qs = require('qs');

export function login(values) {
    // console.log(values)
    var newValues = {...values}
    // var params = new URLSearchParams();
    // params.append('username', values.username)
    // params.append('password', values.password)
    return submit(qs.stringify(newValues), `${consts.API_URL}/login.php`)
}

export function signup(values) {
    return submit(values, `${consts.OAPI_URL}/signup`)
}

function submit(values, url) {
    // console.log('function submit values: ',values)
    // console.log('function submit url: ',url)

    return dispatch => {
        axios.post(url, values)
            .then(resp => {
              var user = {...resp.data.user}
              if(resp.data.status === 'success'){
                dispatch([
                  { type: 'USER_FETCHED', payload: user }
                ])
              }else{
                const configNotification = {
                      message: resp.data.message,
                      color: 'danger'
                    }
                dispatch([
                    { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
                ])
              }
            })
            .catch(e => {
                setTimeout(
                  function() {
                    dispatch([
                        { type: 'MAIN_HIDE_NOTIFICATION', showNotification: false }
                    ])
                  },
                  6000
                );


                // showNotification({
                //   message: "teste",
                //   color: "success"
                // })
                if(e.response && e.response.data && e.response.data.errors && e.response.data.errors  > 0){
                    e.response.data.errors.forEach(
                        error => {
                          // toastr.error('Erro', error))
                          const configNotification = {
                                message: error,
                                color: 'danger'
                              }
                          dispatch([
                              { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
                          ])
                    })
                }else if(e.response && e.response.data && e.response.data.message){
                    // console.log('e.response.data.message: ', e.response.data.message)
                    // toastr.error('Erro', e.response.data.message)

                    const configNotification = {
                          message: e.response.data.message,
                          color: 'danger'
                        }
                    dispatch([
                        { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
                    ])
                }else{
                    // toastr.error('Erro', 'Erro inesperado.')
                    const configNotification = {
                          message: 'Erro inesperado.',
                          color: 'danger'
                        }
                    dispatch([
                        { type: 'MAIN_SHOW_NOTIFICATION', showNotification: true, payload: configNotification }
                    ])
                }

            })
    }
}

export function logout() {
    return { type: 'TOKEN_VALIDATED', payload: false }
}

export function validateToken(token) {
    return dispatch => {
        if (token) {
            dispatch({ type: 'TOKEN_VALIDATED', payload: true})
            // axios.post(`${consts.OAPI_URL}/validateToken`, { token })
            //     .then(resp => {
            //         dispatch({ type: 'TOKEN_VALIDATED', payload: resp.data.valid })
            //     })
            //     .catch(e => dispatch({ type: 'TOKEN_VALIDATED', payload: false }))
        } else {
            // dispatch({ type: 'TOKEN_VALIDATED', payload: false })
            dispatch({ type: 'TOKEN_VALIDATED', payload: true })
        }
    }
}
